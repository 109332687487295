import React from "react";
import { Alert } from "antd";

const HolidayNotice = () => {
    const currentDate = new Date();
    const endDate = new Date("2025-01-02");

    if (currentDate > endDate) {
        return null;
    }

    return (
        <Alert
            message="Holiday Notice"
            description="The agency will be closed from 24 December 2024 - 1 January 2025 for the holidays. Any urgent matter please indicate '[URGENT]' at the beginning of the ticket title and we will respond to your enquiry as soon as we can, else any other matters will be responded on 2 January 2025 when the agency is reopened. Sorry for the inconvenience and happy holiday!"
            type="info"
        />
    );
};

export default HolidayNotice;
