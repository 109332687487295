import React, { useState } from "react";
import SubmitRequestTable from "./SubmitRequestTable";
import { Button, Col, Row } from "antd";
import SubmitRequestsModal from "./SubmitRequestModal";
import { isAdmin } from "../../service/RoleService";
import { DownloadOutlined } from "@ant-design/icons";
import { API_SUBMITS } from "../../constants";
import clientAPI from "../../service/ApiService";
import HolidayNotice from "../HolidayNotice";

function SubmitRequestPage() {
    const [submitRequestModal, setSubmitRequestModal] = useState(0);
    const [refresh, setRefresh] = useState(0);

    const doRefresh = () => {
        setRefresh(refresh + 1);
    };

    const downloadExport = () => {
        clientAPI
            .get(API_SUBMITS + "/export", {
                method: "GET",
                responseType: "blob",
                // headers: {
                //     'Content-Type': 'application/vnd.ms-excel',
                // }
            })
            .then((response) => response.data)
            .then((blob) => {
                console.log(blob);
                console.log(blob.data);
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Excel.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    return (
        <div>
            <HolidayNotice />
            <h2>Submit Requests</h2>
            {isAdmin() && (
                <Row justify="space-between">
                    <Col span={4}>
                        <Button
                            className={"create-cta"}
                            type={"primary"}
                            onClick={() =>
                                setSubmitRequestModal(submitRequestModal + 1)
                            }
                        >
                            Create
                        </Button>
                    </Col>
                    <Col span={4}>
                        <Button
                            className={"create-cta"}
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={() => downloadExport()}
                        >
                            Download
                        </Button>
                    </Col>
                </Row>
            )}
            <SubmitRequestTable refresh={refresh} />
            <SubmitRequestsModal
                submitRequest={null}
                open={submitRequestModal}
                doRefresh={doRefresh}
            />
        </div>
    );
}

export default SubmitRequestPage;
